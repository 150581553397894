import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  innerWidth,
  darkPurple,
  subFont,
  mainPurple,
  mainWhite,
  transHover,
  screen,
} from "../components/variables";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  padding: 120px 0 100px 0;
  @media ${screen.xsmall} {
    min-height: calc(100vh - 476px);
    padding: 220px 0 120px 0;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.small} {
      display: flex;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .col {
      &--img {
        @media ${screen.small} {
          width: 60%;
          padding: 0 70px 0 0;
        }
        @media ${screen.large} {
          padding: 0 90px 0 0;
        }
      }

      &--txt {
        margin: 50px 0 0 0;
        @media ${screen.small} {
          width: 40%;
          margin: 0;
        }

        .title {
          font-family: ${subFont};
          font-size: 1.55rem;
          color: ${darkPurple};
          line-height: 1.16;
          margin: 0 0 20px 0;
          max-width: 400px;
          @media ${screen.xsmall} {
            font-size: 2.52rem;
          }
          @media ${screen.small} {
            font-size: 2.62rem;
          }
          @media ${screen.medium} {
            font-size: 2.72rem;
          }

          span {
            color: ${mainPurple};
          }
        }

        p {
          font-size: 1.05rem;
          margin: 0 0 32px 0;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
          }
          @media ${screen.small} {
            font-size: 1.2rem;
          }
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
        }

        .btn-link {
          all: unset;
          border: 1.5px ${mainPurple} solid;
          background: ${mainWhite};
          color: ${darkPurple};
          font-weight: 600;
          font-size: 1.05rem;
          padding: 8px 24px;
          line-height: 1.5;
          border-radius: 10px;
          cursor: pointer;
          transition: ${transHover};
          display: table;
          @media ${screen.xsmall} {
            font-size: 1.1rem;
            padding: 9px 26px;
          }
          @media ${screen.small} {
            font-size: 1.16rem;
          }
          @media ${screen.medium} {
            font-size: 1.23rem;
            padding: 10px 30px;
          }

          @media ${screen.withCursor} {
            &:hover {
              border: 1.5px #6320ff solid;
              background-image: linear-gradient(to right, #67b6ff, #6300ff);
              color: ${mainWhite};
            }
          }
        }
      }
    }
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Wrapper>
      <div className="inner-wrapper">
        <div className="col col--img">
          <StaticImage
            src="../images/page-not-found-clearR.png"
            alt="Artwork"
            placeholder="blurred"
          />
        </div>
        <div className="col col--txt">
          <h1 className="title">
            The Computer Says <span>No.</span>
          </h1>
          <p>Sorry, We couldn’t find the page you are looking for.</p>

          <Link className="btn-link" to="/">
            GET YOURSELF HOME
          </Link>
        </div>
      </div>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
